//created by Peggy on 2021/1/29
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Back from '@/components/Back'
import ModalInfo from '@/components/Modal/Info'
import { Form, Input, Steps, Button, Select, message, Breadcrumb, Tooltip } from 'antd'
import { QuestionCircleFilled } from '@ant-design/icons'
import { useHistory, useParams } from 'react-router-dom'
import HelloSign from 'hellosign-embedded'
import { stateList } from '@/types/enum'

import { saveSignature, finishSignature, getSignature } from '@/api/merbership-api'
import { useModel } from 'use-reaction'
import { user, getUser } from '@/model/user'

import icon_campaign from '@/images/icons/icon_campaign.svg'
import icon_donation from '@/images/icons/icon_donation.svg'

import { view } from '@/model/view'
import { TheField } from '@/components/GoogleTranslate'

const client = new HelloSign({
  clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
  skipDomainVerification: process.env.NODE_ENV === 'development',
  debug: false
})

const AgreementContainer = styled.div`
  .hidden {
    display: none;
  }
  .show {
    display: block;
  }
`

const Agreement = () => {
  const {
    store: {
      info: { tags: disciplines, ...others }
    },
    doAction
  } = useModel(user)
  const {
    store: { isMobile }
  } = useModel(view)
  const [current, setCurrent] = useState(0)
  const [show, setShow] = useState(false)
  const [emailModal, setEmailModal] = useState(false)
  const [form] = Form.useForm()
  const history = useHistory()
  const { resign } = useParams() as any
  useEffect(() => {
    client?.on('sign', () => {
      console.log('The document has been signed!')
    })

    // client.on("finish", () => {
    //   console.log("The document has been signed!");
    //   finishSignature({}).then((data) => setShow(true));
    // });

    client?.on('close', () => {
      console.log('close')
    })

    client?.on('cancel', () => {
      console.log('cancel')
    })

    client?.on('finish', () => {
      console.log('finish')
      //   console.log("The document has been signed!");
      finishSignature({ loop: 0 }).then((data) => {
        doAction(getUser)
        setShow(true)
      })
    })

    client?.on('error', (data: any) => {
      const errStr = `Something went wrong! Please try again. Error code: ${data.code}`
      message.error(errStr)

      client.close()
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      ...others,
      disciplines
    })
    if (resign) {
      getSignature().then((data) => {
        let { socialSecurityNumber, einNumber, ..._temp } = data
        form.setFieldsValue({
          ...others,
          disciplines,
          ..._temp,
          identificationType: socialSecurityNumber ? 'socialSecurityNumber' : 'einNumber',
          socialSecurityNumberValue: socialSecurityNumber || einNumber
        })
      })
    }
    // eslint-disable-next-line
  }, [])

  const onFinish = (values: any) => {
    // setShow(true);
    const { identificationType, socialSecurityNumberValue, ...others } = values
    let _data = {
      ...others,
      [identificationType]: socialSecurityNumberValue
    }
    saveSignature(_data).then((data) => {
      client.open(data.sign_url)
      // setEmailModal(false);
      // history.push("/dashboard/membership/fiscal");
    })
    // client.open(sign_url);
    console.log('Success:', values)
  }

  const onFinishFailed = (e: any) => {
    console.log('Failed:', e)
    message.error(e.errorFields[0].errors[0])
  }
  return (
    <AgreementContainer className="agreement-container common-container">
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/dashboard/membership/fiscal">Fiscal sponsorship</Breadcrumb.Item>
        <Breadcrumb.Item>Fiscal sponsorship agreement</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Fiscal sponsorship agreement</h2>
      <Steps current={current}>
        <Steps.Step title="Sponsored artist information" />
        <Steps.Step title="Mailing information" />
      </Steps>
      <Form
        layout="vertical"
        size="large"
        name="basic"
        initialValues={{
          cardType: 'SSN'
        }}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className={current === 0 ? 'show' : 'hidden'}>
          <div className={isMobile ? '' : 'flex'}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Enter first Name'
                }
              ]}
            >
              <Input maxLength={30} placeholder="Enter first Name" />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Enter last name'
                }
              ]}
            >
              <Input maxLength={30} autoComplete="off" placeholder="Enter last name" />
            </Form.Item>
          </div>
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[
              {
                required: false,
                whitespace: true,
                message: 'company Name '
              }
            ]}
          >
            <Input maxLength={30} autoComplete="off" placeholder="Company name" />
          </Form.Item>
          <Form.Item
            label={
              <div>
                Email address
                <Tooltip
                  className="tooltip-field-item"
                  title="We'll send you the contract to this email address"
                >
                  <QuestionCircleFilled />
                </Tooltip>
              </div>
            }
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                whitespace: true,
                message: 'email invalid'
              }
            ]}
          >
            <Input maxLength={50} autoComplete="off" placeholder="Email address" />
          </Form.Item>
          <Form.Item
            label="Phone number"
            name="phone"
            normalize={(e) => {
              return e.replace(/[^\d]*/g, '').replace(/(\d{3})(?=\d{2})/g, '$1-')
            }}
            rules={[
              {
                required: true,
                message: 'Enter phone number'
              }
            ]}
          >
            <Input maxLength={12} autoComplete="off" placeholder="Enter phone number" />
          </Form.Item>
          <p className={`identification-items ${isMobile ? '' : 'flex'}`}>
            <Form.Item
              name="identificationType"
              className="select-container"
              label="Identification type"
              initialValue="socialSecurityNumber"
              style={{ marginRight: 0, flex: '0 0 30%', borderRight: 0 }}
            >
              <Select
                placeholder="Identification type"
                onChange={() =>
                  form.setFieldsValue({
                    socialSecurityNumberValue: ''
                  })
                }
                getPopupContainer={() =>
                  document.getElementsByClassName('common-container')[0] as any
                }
              >
                <Select.Option value="socialSecurityNumber">Social Security Number</Select.Option>
                <Select.Option value="einNumber">EIN Number</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) =>
                prevValues.identificationType !== curValues.identificationType
              }
            >
              {({ getFieldValue }) => (
                <Form.Item
                  label={
                    getFieldValue('identificationType') === 'socialSecurityNumber'
                      ? 'Social Security Number'
                      : 'Employer Identification Number'
                  }
                  name="socialSecurityNumberValue"
                  normalize={(e) => {
                    return getFieldValue('identificationType') === 'socialSecurityNumber'
                      ? e
                          .replace(/[^\d]*/g, '')
                          .replace(/^(\d{3})/, '$1-')
                          .replace(/-(\d{2})/, '-$1-')
                          .replace(/(\d)-(\d{4}).*/, '$1-$2')
                      : e
                          .replace(/[^\d]*/g, '')
                          .replace(/^(\d{2})/, '$1-')
                          .replace(/(\d)-(\d{7}).*/, '$1-$2')
                  }}
                  rules={[
                    {
                      required: true,
                      message:
                        getFieldValue('identificationType') === 'socialSecurityNumber'
                          ? 'Social Security Number'
                          : 'Employer Identification Number'
                    }
                  ]}
                >
                  <Input
                    maxLength={30}
                    autoComplete="off"
                    placeholder={
                      getFieldValue('identificationType') === 'socialSecurityNumber'
                        ? 'Social Security Number'
                        : 'Employer Identification Number'
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
          </p>

          <Form.Item
            label="Legal name"
            name="legalName"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Legal name'
              }
            ]}
          >
            <Input
              autoComplete="off"
              placeholder="Please enter the legal name associated with tax payer ID "
            />
          </Form.Item>
          <Form.Item
            label="Associated names"
            name="associatedNames"
            rules={[
              {
                required: false,
                whitespace: true,
                message: 'Any associated names'
              }
            ]}
          >
            <Input
              autoComplete="off"
              placeholder="Enter any other names that people may use to identify you (optional)"
            />
          </Form.Item>
        </div>
        <div className={current === 1 ? 'show' : 'hidden'}>
          <Form.Item
            label="Address"
            name="address1"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'Address'
              }
            ]}
          >
            <Input maxLength={30} placeholder="Address" />
          </Form.Item>
          <Form.Item
            label="Address 2 (Optional)"
            name="address2"
            rules={[
              {
                whitespace: true,
                message: 'Address 2 (Optional)'
              }
            ]}
          >
            <Input maxLength={30} placeholder="Address 2 (Optional)" />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[
              {
                required: true,
                whitespace: true,
                message: 'City'
              }
            ]}
          >
            <Input maxLength={30} placeholder="City" />
          </Form.Item>
          <p className={isMobile ? '' : 'flex'}>
            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'State'
                }
              ]}
            >
              <Select
                placeholder="State"
                getPopupContainer={() =>
                  document.getElementsByClassName('common-container')[0] as any
                }
              >
                {Object.values(stateList)?.map((item) => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Zip Code"
              name="zipCode"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter your 5-digit zip code.',
                  validator: (_, value) => {
                    if(/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)){
                      return Promise.resolve()
                    }else{
                      return Promise.reject()
                    }
                  }
                }
              ]}
            >
              <Input  minLength={5} placeholder="Zip Code" />
            </Form.Item>
          </p>
        </div>
        <p className="flex btn-wrap">
          {current === 1 && (
            <>
              <Back onClick={() => setCurrent(0)}>{isMobile ? 'Previous' : 'Previous step'}</Back>
              <Button
                type="primary"
                onClick={() => {
                  // setEmailModal(true);
                  form.submit()
                }}
              >
                Submit
              </Button>
            </>
          )}
          {current === 0 && (
            <>
              {/* <span></span> */}
              <Button
                type="primary"
                onClick={() => {
                  form
                    .validateFields([
                      'first_name',
                      'last_name',
                      'email',
                      'number',
                      'phone',
                      'socialSecurityNumberValue',
                      'legalName',
                      'associatedNames'
                    ])
                    .then(() => {
                      setCurrent(1)
                    })
                }}
              >
                Continue
              </Button>
            </>
          )}
        </p>
      </Form>
      <ModalInfo
        visible={emailModal}
        onClose={() => {
          setEmailModal(false)
          history.push('/dashboard/membership/fiscal')
        }}
        footer={[
          <Button
            onClick={() => {
              setEmailModal(false)
            }}
          >
            Cancel
          </Button>,
          <Button
            type="primary"
            onClick={() => {
              form.submit()
            }}
          >
            Confirm
          </Button>
        ]}
        img={icon_campaign}
      >
        <h4>You’re almost there!</h4>
        <p>
          Affer you hit CONFIRM, we will send the contract to <b>{form.getFieldValue('email')}</b>.
          Please check your inbox and open the email with the subject "Signature Requested by The
          Field". Click on REVIEW & SIGN to sign contract.
        </p>
      </ModalInfo>
      <ModalInfo
        visible={show}
        onClose={() => {
          setShow(false)
          history.push('/dashboard/membership/fiscal')
        }}
        footer={[
          <Button
            type="primary"
            onClick={() => {
              setShow(false)
              history.push('/dashboard/membership/fiscal')
            }}
          >
            got it
          </Button>
        ]}
        img={icon_donation}
      >
        <h4>Fiscal Sponsorship agreement completed</h4>
        <p>
          You have successfully signed up for fiscal sponsorship with The Field. You can start
          fundraising right away!
        </p>
      </ModalInfo>
    </AgreementContainer>
  )
}

export default Agreement
